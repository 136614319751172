import React from 'react';

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { EmailInput, FormInput, FormMaskedTextBox, FormDatePicker } from "../../../common/utils/form-components"
import { emailValidator, passwordValidationMessage, confirmPasswordValidationMessage, phoneValidator, firstNameValidator, lastNameValidator, startDateValidator, endDateValidator } from "../../../common/utils/validators"
import { Upload, UploadFileStatus } from "@progress/kendo-react-upload";

export default function CampaignData(props) {
    const [email, setEmail] = React.useState("");
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");


    let onSaveRequest = (files, _, onProgress) => {
        console.log(files)

        const uid = files[0].uid;
        console.log(uid)

    }

    React.useEffect(() => {
        //checks for invalid date input
        if (startDate > endDate && startDate && endDate) {
            props.onTriggerAlert('error')
            props.setAlertMessage("Start Date cannot be greater than End Date & vice versa!")
        }
    }, [startDate, endDate]);

    return (
        <div className="campaign-data">

            <Card style={{
                minWidth: 350,
                padding:"10px 10px 20px 10px"
            }}>

                <CardTitle className='card-title'>
                    Register your Campaign
                </CardTitle>

                <CardBody>
                    <Form render={formRenderProps => <FormElement >
                        <fieldset className={'k-form-fieldset'}>

                            {/* Campaign image upload */}
                            <Upload
                                batch={false}
                                multiple={true}
                                defaultFiles={[]}
                                withCredentials={false}
                                saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
                                removeUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/remove"}
                                restrictions={{
                                    allowedExtensions: [".jpg", ".png", ".jpeg"],
                                }}
                            // onAdd={(e)=>console.log(e)}
                            />

                            {/* Campaign Title */}
                            <Field name={'campaignTitle'} component={FormInput} label={'Campaign Title'} /*validator={firstNameValidator} */ />


                            <Field name={'content'} component={FormInput} label={'Description'} /*validator={lastNameValidator}*/ />

                            <div
                                className="sign-up-fields-beside "
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: "-12px",
                                    width: "100%"
                                }}>
                                <Field onChange={(e) => setStartDate(e.value)} name={'startDate'} component={FormDatePicker} label={'Start Date'} hint={'Hint: Should be less than the end date'}validator={startDateValidator} />
                                <Field onChange={(e) => setEndDate(e.value)} name={'endDate'} component={FormDatePicker} label={'End Date'} hint={'Hint: Should be greater than the start date'} validator={endDateValidator} />


                            </div>
                        </fieldset>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>
    );
}

