import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';
import logo from "../../assets/images/BuildIdLogo.png"
import { useNavigate } from "react-router-dom";

export default function CampaignCardDialog(props) {
    const navigate = useNavigate();

    return (
        <div>
            <Dialog  title={" "} onClose={() => props.setCardDialogVis(false)}>
                <h1 style={{ marginLeft: "50px" }}>Coke</h1>

                <div
                    style={{
                        margin: "20px 20px 0px 20px",
                        textAlign: "center"
                    }}
                >

                    <img src={logo} alt="logo" style={{ padding: "0px 0px 5px 0px" }} />

                    <Card style={{ margin: "15px" }} className='campaign-card-dialog'>
                        <div style={{ display: "flex", gap: '30px', margin: "-10px 0px 50px 0px", justifyContent: "space-between", fontWeight: "700" }}>
                            <div>Type: <span>Banner</span></div>
                            <div>Status: <span style={{ color: "green" }}>Live</span></div>
                        </div>

                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        <div style={{ display: "flex", gap: '20px', margin: "50px 0px -10px 0px", justifyContent: "space-between", fontWeight: "700" }}>
                            <div>Total Clicks: <span>33</span></div>
                            <div>Total Views: <span>1234</span></div>
                            <div>Average/ Site: <span>22</span></div>
                        </div>
                    </Card>
                </div>

                <DialogActionsBar>
                    {/* <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => props.setCardDialogVis(false)}>No</button> */}
                    <Button
                        themeColor={'primary'}
                        // onClick={() => props.setCardDialogVis(false)}
                        onClick={() => navigate("/Campaign-Sites")}
                    >
                        View Sites
                    </Button>
                </DialogActionsBar>
            </Dialog>

        </div>
    );
}

