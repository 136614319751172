import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import logo from '../../../assets/images/logo/logo01.png'
import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { FormInput, FormMaskedTextBox, FormDateInput } from "../../../common/utils/form-components"
import { cardValidator, cvcValidator, requiredValidator } from "../../../common/utils/validators"
import { CardSelector } from '../Settings/CardSelector';
import { Checkbox } from "@progress/kendo-react-inputs";


const center = {
    lat: 51.048615,
    lng: -114.070847
};

export default function CampaignOverview() {

    const [savePaymentCheckbox, setSavePaymentCheckbox] = React.useState(false);
  

    return (
        <div className="campaign-overview-container" >
            <div style={{ display: "flex", gap: "50px", marginTop: "25px" }}>
                <div style={{
                    display: "flex",
                    borderRadius: "8px",
                    boxShadow: " 0px 0px 8px 2px lightgray",
                    // height: "fit-content",
                    backgroundColor:"#ffffff",
                    padding:"16px",
                    overflow:"auto"
                }}>

                    <div className="overview-card-container" style={{ alignContent: "space-between", width: "55%", height:"100%" }}>

                        <div style={{ textAlign: "center", margin: "auto", justifyContent:"center", alignContent:"center", alignItems:"center" }}>
                            <img src={logo} alt="logo" style={{
                                padding: "10px 0px 20px 0px",
                                borderRadius: "8px",
                                border: "solid 2px lightgray",
                                // width: "100%"
                                height:"7vh"
                            }} />
                        </div>

                        <div 
                        className='overview-box-containers'
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "auto",
                        }}>
                            <div style={{ fontSize: "1.4vmin" }}>
                                <h1>
                                    Campaign Title
                                </h1>

                                <div>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentiall
                                </div>
                            </div>


                            <div style={{ fontSize: "1.4vmin" }}>
                                <h1>
                                    Plan Title
                                </h1>
                                <div>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentiall
                                </div>
                            </div>

                            <div className="overview-start-end-date" >
                                <h6 style={{ fontSize: "1.7vmin" }}>Start Date: <span style={{ fontWeight: "500", color: "#707070" }}> March 2, 2024</span></h6>
                                <h6 style={{ fontSize: "1.7vmin" }}>End Date: <span style={{ fontWeight: "500", color: "#707070" }}> August 21, 2024</span></h6>
                            </div>

                        </div>
                    </div>

                    {/* <span className="campaign-overview-vertical-divider" /> */}
                    <span style={{margin:"14px"}}></span>


                    <div className='payment-container'>

                        <Checkbox onChange={() => setSavePaymentCheckbox(!savePaymentCheckbox)} label={"Use saved payment method?"} />

                        {/* Payment Form */}
                        {!savePaymentCheckbox && <Form render={formRenderProps => <FormElement >
                            <fieldset className={'k-form-fieldset'}>

                                <div style={{ marginTop: "30px" }}>
                                    <Field key={'cardType'} name={'cardType'} component={CardSelector} validator={requiredValidator} />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{
                                            width: '75%',
                                            marginRight: '25px'
                                        }}>
                                            <Field key={'cardNumber'} id={'cardNumber'} name={'cardNumber'} label={'Card Number'} hint={'Hint: Your Credit Card Number'} mask={'0000-0000-0000-0000'} component={FormMaskedTextBox} validator={cardValidator} />
                                        </div>
                                        <Field key={'cvc'} id={'cvc'} name={'cvc'} label={'CVC Number'} hint={'Hint: The last 3 digids on the back of the Card'} mask={'000'} component={FormMaskedTextBox} validator={cvcValidator} />
                                    </div>
                                    <Field key={'expireDate'} id={'expireDate'} name={'expireDate'} label={'Expiration Date'} component={FormDateInput} validator={requiredValidator} />
                                    <Field key={'cardHolder'} id={'cardHolder'} name={'cardHolder'} label={'Card Holder Name'} component={FormInput} validator={requiredValidator} />
                                </div>
                            </fieldset>
                        </FormElement>} />}

                        {/* Payment Total */}
                        <div style={{ fontWeight: "700", marginTop: "auto", padding:"8px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <span style={{ color: "#083db8", marginRight:"4px" }}>Subtotal: </span> <span> $220.00</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <span style={{ color: "#083db8", marginRight:"4px"  }}>{"GST (5%): "}</span> <span> $11.00</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <span style={{ color: "#083db8", marginRight:"4px"  }}>Total: </span> <span> $231.00</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="overview-map-container" >
                    <GoogleMap
                        mapContainerStyle={{
                            width: '45vmax',
                            // height: '67vh',
                            height:"100%",
                            borderRadius: "8px",
                            boxShadow: " 0px 0px 8px 2px lightgray"
                        }}
                        center={center}
                        zoom={12}
                    // onLoad={onLoad}
                    // onUnmount={onUnmount}
                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        <Marker position={center} />
                    </GoogleMap>
                </div>
            </div>

        </div>
    );
}

