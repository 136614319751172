import React, { useState } from "react";
import pageheader from "../assets/images/page-header.jpg";
import { useNavigate } from "react-router-dom";
import { groupBy } from "../common/utils/utils";
import { fetchFAQ } from "../api/MarketingApi";

export default function Faq() {
  const navigate = useNavigate();

  const [activeFaq, setActiveFaq] = useState(null);
  const [faqList, setFAQList] = useState(null);

  let FetchFAQList = async () => {
    let resp = await fetchFAQ();

    setFAQList(resp)
  }

  React.useEffect(() => {
    try {
      FetchFAQList()
    }
    catch (error) {
      console.log(error);
     }
  }, [])


  return (
    faqList ? <>
      <section
        className="hero-area bg_img"
        style={{
          backgroundImage: `url(${pageheader})`,
        }}>
        <div className="container">
          <h1 className="title m-0"> FAQs</h1>
        </div>
      </section>
     {window.location.pathname != "/faqapp" && <div className="breadcrumb-section">
        <div className="container">
          <ul className="breadcrumb">
            <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}> Home </li>
            <div style={{ margin: "0px 10px 0px 10px" }}> - </div>
            <li>FAQs</li>
          </ul>
        </div>
      </div>}

      <section className="faq-section padding-top padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq-area">
                {Object.entries(groupBy(faqList, 'category')).map((category, i) => (
                  <React.Fragment key={i}>
                    <h3 className="main-title">{category[0]}</h3>
                    <div className="faq-wrapper">

                      {category[1].sort((a, b) => a.displayOrder - b.displayOrder).map((item, index) => (
                        item.active ?  <div
                          className={
                            activeFaq === item.subCategory
                              ? "faq-item open"
                              : "faq-item"
                          }
                          key={index}>
                          <div
                            className="faq-title"
                            onClick={() => {
                              setActiveFaq((preValue) =>
                                preValue !== item?.subCategory ? item?.subCategory : null
                              );
                            }}>
                            <h6 className="title">{item.subCategory}</h6>
                            <span className="right-icon"></span>
                          </div>
                          {activeFaq === item.subCategory && (
                            <div className="faq-content">
                              {item.description.split(/\r?\n|\r|\n/g).map((desc, index) => <p key={index}>{desc}</p>)}    {/* Splits String so it creates a new line to be more readable */}
                            </div>
                          )}
                        </div> : ""
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="col-md-4" style={{ display: "none" }}>
              <aside className="sidebar">
                <div className="widget widget-category">
                  <div className="widget-header">
                    <h5 className="title">faq list</h5>
                  </div>
                  <ul>
                    <li>
                      <a href="faq.html">general</a>
                    </li>
                    <li>
                      <a href="faq.html">technical</a>
                    </li>
                    <li>
                      <a href="faq.html">licensing</a>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </> : ""
  );
}
