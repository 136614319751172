import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import { createAccountAdsClients } from "../../../api/LoginApi"
import { useNavigate } from "react-router-dom";

//Sign Up Step Pages
import SignUp from "../SignUp/SignUp"
import BusinessDetails from './BusinessDetails';

const stepPages = [SignUp, BusinessDetails];

export default function SignUpHub(props) {
    const navigate = useNavigate();

    // Client Information States
    const [userDetails, setUserDetails] = React.useState({
        FirstName: "",
        LastName: "",
        UserEmail: "",

        BusinessName: "",
        ContactNumber: "",
        Address: "",
        City: "",
        Province: "",
        PostalCode: "",
        Country: "",
        // businessEmail:"",
        // platform: "x",

        Password: "",
    });

    // Step States
    const [step, setStep] = React.useState(0);
    const [steps, setSteps] = React.useState([{ label: 'User Details' }, { label: 'Business Details' }]);

    const [isFormValid, setIsFormValid] = React.useState(false);

    // Instantiate to load as a Component in the return()
    let DisplayComponent = stepPages[step]

    //step vars
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const isPreviousStepsValid = steps.slice(0, step);


    // Function to update the data when the user adds Input
    let onDataChange = (field, input) => {
        setUserDetails({
            ...userDetails,
            [field]: input
        })
    }


    const onStepSubmit = React.useCallback(
        () => {
            // setSteps(currentSteps);
            setStep(() => Math.min(step + 1, lastStepIndex));

            if (isLastStep) {
                handleLogin();
            }
        },
        [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
    );

    async function handleLogin() {
        try {
            let loginResult = await createAccountAdsClients(userDetails);
            await localStorage.setItem("token", loginResult?.data?.token ?? null);

            props.setAlertMessage("Successfully created an account")
            props.onTriggerAlert('success')

            navigate("/Campaign-Home");

        }
        catch (error) {
            props.setAlertMessage(error?.response?.data ? error?.response?.data.errorMessage : 'Error')
            props.onTriggerAlert('error')
        }
    }


    const onPrevStepClick = React.useCallback(
        (event) => {
            // sets empty every time the page is re-rendered
            setIsFormValid(false)
            onDataChange("FirstName", '')
            onDataChange("LastName", '')

            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "space-between", marginTop: "6px" }}>

            <Stepper style={{ marginBottom: "auto", width: "100vw"  }} value={step} linear={true} items={steps} />

            {/* Display of the Page */}
            <DisplayComponent
                onClick={props.handleBackClick}
                onDataChange={(field, input) => onDataChange(field, input)}
                setIsFormValid={setIsFormValid}
                isFormValid={isFormValid}
                userDetails={userDetails}
            />

            <div className="sign-up-stepper-buttons" style={{ display: "flex", justifyContent: 'space-between',/* margin: "auto 0px auto 0px",*/ padding: "10px", width: "55vw" }}>

                <div>
                    <Button
                        style={{ padding: "10px" }}
                        themeColor={'secondary'}
                        onClick={() => props.setActivePage("login")}>
                        Login Home
                    </Button>
                </div>

                <div style={{ alignSelf: "center" }}>Step {step + 1} of {steps.length}</div>

                <div style={{ display: "flex" }}>
                    {step !== 0 ? (
                        <Button
                            style={{ padding: "10px", marginRight: "16px" }}
                            onClick={onPrevStepClick}
                        >
                            Previous
                        </Button>
                    ) : undefined}
                    <Button
                        style={{ padding: "10px", position: "inherit" }}
                        themeColor={'primary'}
                        // disabled={isLastStep ? !isPreviousStepsValid : false}
                        disabled={isFormValid ? false : true}
                        onClick={onStepSubmit}
                    >
                        {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                </div>
            </div>
        </div>
    );
};
