import * as React from 'react';
import {
    Notification,
    NotificationGroup,
} from '@progress/kendo-react-notification';
import { Fade, Slide } from '@progress/kendo-react-animation';


export default function Alerts(props) {

    const { success, error, warning, info, none } = props.alertState;

    return (
        <React.Fragment>

            <NotificationGroup
                style={{
                    top: 50,
                    right: 15,
                    width: '95%',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse',
                }}
            >
                <Slide direction={success ? 'down' : 'up'}>
                    {success && (
                        <Notification
                            type={{
                                style: 'success',
                                icon: true,
                            }}
                            closable={true}
                            onClose={() =>
                                props.setAlertState({
                                    ...props.alertState,
                                    success: false,
                                })
                            }
                        >
                            {/* <span>Your data has been saved.</span> */}
                            <span>{props.alertMessage}</span>
                        </Notification>
                    )}
                </Slide>
                <Slide direction={error ? 'down' : 'up'}>
                    {error && (
                        <Notification
                            type={{
                                style: 'error',
                                icon: true,
                            }}
                            closable={true}
                            onClose={() =>
                                props.setAlertState({
                                    ...props.alertState,
                                    error: false,
                                })
                            }
                        >
                            {/* <span>Oops! Something went wrong ...</span> */}
                            <span>{props.alertMessage}</span>
                        </Notification>
                    )}
                </Slide>
                <Slide direction={warning ? 'down' : 'up'}>
                    {warning && (
                        <Notification
                            type={{
                                style: 'warning',
                                icon: true,
                            }}
                            closable={true}
                            onClose={() =>
                                props.setAlertState({
                                    ...props.alertState,
                                    warning: false,
                                })
                            }
                        >
                            <span>Your password will expire in 2 days!</span>
                            {/* <span>{props.alertMessage}</span> */}
                        </Notification>
                    )}
                </Slide>
                <Slide direction={info ? 'down' : 'up'}>
                    {info && (
                        <Notification
                            type={{
                                style: 'info',
                                icon: true,
                            }}
                            closable={true}
                            onClose={() =>
                                props.setAlertState({
                                    ...props.alertState,
                                    info: false,
                                })
                            }
                        >
                            <span>You have 1 new message!</span>
                            {/* <span>{props.alertMessage}</span> */}
                        </Notification>
                    )}
                </Slide>
                <Slide direction={none ? 'down' : 'up'}>
                    {none && (
                        <Notification
                            type={{
                                style: 'none',
                                icon: false,
                            }}
                            closable={true}
                            onClose={() =>
                                props.setAlertState({
                                    ...props.alertState,
                                    none: false,
                                })
                            }
                            style={{
                                overflow: 'visible',
                            }}
                        >
                            <span>Hanna Moos likes your status.</span>
                            {/* <span>{props.alertMessage}</span> */}
                        </Notification>
                    )}
                </Slide>
            </NotificationGroup>
        </React.Fragment>
    );
};
