import * as React from 'react';
import "../../assets/css/Login.css";

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { EmailInput } from "../../common/utils/form-components"
import { emailValidator } from "../../common/utils/validators"
import { Button } from '@progress/kendo-react-buttons';
import { generateResetCode } from '../../api/LoginApi';
import { forgotPasswordAdsClients } from "../../api/LoginApi"

export default function ForGotPassword(props) {
    const [email, setEmail] = React.useState("");

    async function handlePasswordRecoveryClick() {
        try {
            await forgotPasswordAdsClients(email);
            props.onTriggerAlert('success')
            props.setAlertMessage("If there is an account associated with this email, you will receive a reset code.")

            props.setActivePage("verify-code")
        }
        catch (error) {
            props.onTriggerAlert('error')
            // props.setAlertMessage(error?.response?.data ? error.response.data.errorMessage : 'Email does not exist')
            props.setAlertMessage( 'Email does not exist')
        }
    }

    let handleBackClick = () => {
        props.setActivePage("login")
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
            <Card className="login-container" >

                <CardTitle>
                    Forgot Password
                </CardTitle>

                <CardBody>

                    <Form onSubmit={handlePasswordRecoveryClick} render={formRenderProps => <FormElement style={{
                        // maxWidth: 650
                    }}>
                        <fieldset className={'k-form-fieldset'}>

                            {/* Email */}
                            <FieldWrapper>
                                <Field onChange={(e) => setEmail(e.value)} name={"email"} type={"email"} component={EmailInput} label={"Email"} validator={emailValidator} />
                            </FieldWrapper>

                        </fieldset>
                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", margin: "45px 0px 0px 5px" }}>

                            <Button
                                onClick={handleBackClick}
                                themeColor={'secondary'}
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            >
                                Back
                            </Button>
                            <Button
                                themeColor={'primary'}
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                disabled={!formRenderProps.allowSubmit}
                            >
                                Continue
                            </Button>

                        </div> */}

                        <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "auto", padding: "10px", width: "100%" }}>

                            <Button
                                style={{ padding: "0px 50px 0px 50px", position: "inherit", width: "15%", marginTop: "15px" }}
                                themeColor={'secondary'}
                                onClick={handleBackClick}
                            >
                                Back
                            </Button>
                            <Button
                                style={{ padding: "0px 50px 0px 50px", position: "inherit", width: "15%", marginTop: "15px" }}
                                themeColor={'primary'}
                                type={'submit'}
                            >
                                Continue
                            </Button>
                        </div>

                        <span className={"k-form-separator"} style={{ margin: "50px 0px 35px 0px" }} />

                        <div className="back-button" onClick={() => props.setActivePage("verify-code")}>
                            I have a Reset Code
                        </div>

                    </FormElement>} />
                </CardBody>
            </Card>
        </div>
    )
}

