import React from 'react';
import "../../assets/css/Login.css";

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { emailValidator, passwordValidationMessage, confirmPasswordValidationMessage, phoneValidator, firstNameValidator, lastNameValidator } from "../../common/utils/validators"
import { ChunkProgressBar } from '@progress/kendo-react-progressbars';
import { BIGrey, BIGreen, BIRed, BIYellow, BIBlue } from "../../assets/buildidColors";
import { FaCheck, FaTimes } from "react-icons/fa";
import { adsCreateAccount } from '../../api/LoginApi';
import { sha256 } from 'crypto-hash';
import { Button } from '@progress/kendo-react-buttons';


export default function ResetPassword(props) {

    const [isVisible1, setIsVisible1] = React.useState(true);
    const [isVisible2, setIsVisible2] = React.useState(true);
    const [isFormValid, setIsFormValid] = React.useState(false);

    //fields
    const [password, setPassword] = React.useState("");
    const [retypePassword, setRetypePassword] = React.useState("");
    const [passwordReqs, setPasswordReqs] = React.useState(0);


    // Button Disabler A.K.A validator
    React.useEffect(() => {
        if (password === retypePassword && passwordReqs === 4) {
            // triggers button to un-disable
            setIsFormValid(true)
        }
    }, [password, retypePassword])

    //Login Password Progress bar
    const progressStyles = {
        background:
            passwordReqs === 1 ? BIRed :
                passwordReqs === 2 ? "rgb(250, 114, 25)" : //orange
                    passwordReqs === 3 ? BIYellow :
                        "green"
    };

    const emptyStyles = {
        background: "rgb(127, 127, 127)"
    };

    // Password Requirements Coutner for Progress bar
    let passReqCounter = async (pass) => {
        let count = 0;

        if (pass.length >= 8) count += 1;
        if (pass.match(/(?=.*\d)/)) count += 1;
        if (pass.match(/(?=.*[A-Z])/)) count += 1;
        if (pass.match(/(?=.*[a-z])/)) count += 1;

        setPassword(pass);
        //To check if re-typed password is the same as this one
        // props.onDataChange("password", hashPassword(pass))    // Changes the password of the object 

        let hashedPass = await sha256(pass)

        setPasswordReqs(count);
    }

    let handleBackClick = () => {
        props.setActivePage("login")
    }
    return (
        <div className="sign-up-form" style={{ display: "flex", justifyContent: "center" }}>
            <Card className="login-card" style={{ minWidth: 350 }}>

                <CardTitle className='card-title'>
                    Reset Password
                </CardTitle>

                <CardBody>
                    <Form render={formRenderProps => <FormElement >
                        <fieldset className={'k-form-fieldset'}>

                            {/* Password */}
                            <FieldWrapper>
                                <div className='k-form-field-wrap' >
                                    <Input onChange={(e) => { passReqCounter(e.value) }} label={'Password'} name={'password'} type={isVisible1 ? 'password' : 'text'} required={true} minLength={6} />
                                    {isVisible1 ?
                                        <span className="k-icon-xxl k-font-icon reset-pass-icon k-i-eye-slash" onClick={() => setIsVisible1(!isVisible1)}></span> :
                                        <span className="k-icon-xxl k-font-icon reset-pass-icon k-i-eye" onClick={() => setIsVisible1(!isVisible1)}></span>
                                    }
                                </div>
                            </FieldWrapper>

                            {/* Password Strength Protector */}
                            <FieldWrapper>
                                <div className=" k-form-field-wrap strength-bar">
                                    <ChunkProgressBar value={passwordReqs} max={4} chunkCount={4} progressStyle={progressStyles} emptyStyle={emptyStyles} />
                                </div>

                                <div className="password-checklist">
                                    <p style={{ color: password !== '' ? password.length >= 6 ? BIGreen : BIRed : BIGrey }}>
                                        {
                                            password === '' || password.length >= 6
                                                ? <FaCheck className="icon-position" style={{ color: password.length >= 8 ? BIGreen : BIGrey }} />
                                                : <FaTimes className="icon-position" style={{ color: BIRed }} />
                                        } At least 8 characters
                                    </p>

                                    <p style={{ color: password !== '' ? password.match(/(?=.*\d)/) ? BIGreen : BIRed : BIGrey }}>
                                        {
                                            password === '' || password.match(/(?=.*\d)/)
                                                ? <FaCheck className="icon-position" style={{ color: password.match(/(?=.*\d)/) ? BIGreen : BIGrey }} />
                                                : <FaTimes className="icon-position" style={{ color: BIRed }} />
                                        } At least 1 number
                                    </p>

                                    <p style={{ color: password !== '' ? password.match(/(?=.*[A-Z])/) ? BIGreen : BIRed : BIGrey }}>
                                        {
                                            password === '' || password.match(/(?=.*[A-Z])/)
                                                ? <FaCheck className="icon-position" style={{ color: password.match(/(?=.*[A-Z])/) ? BIGreen : BIGrey }} />
                                                : <FaTimes className="icon-position" style={{ color: BIRed }} />
                                        } At least 1 capital letter
                                    </p>

                                    <p style={{ color: password !== '' ? password.match(/(?=.*[a-z])/) ? BIGreen : BIRed : BIGrey }}>
                                        {
                                            password === '' || password.match(/(?=.*[a-z])/)
                                                ? <FaCheck className="icon-position" style={{ color: password.match(/(?=.*[a-z])/) ? BIGreen : BIGrey }} />
                                                : <FaTimes className="icon-position" style={{ color: BIRed }} />
                                        } At least 1 lowercase letter
                                    </p>
                                </div>
                            </FieldWrapper>

                            {/* Retype Password */}
                            <FieldWrapper>
                                <div className='k-form-field-wrap'>
                                    <Input onChange={(e) => setRetypePassword(e.value)} label={'Retype Password'} name={'retype-password'} /*valid={(e) => e.value === password ? true : false}*/ type={isVisible2 ? 'password' : 'text'} validationMessage={confirmPasswordValidationMessage} required={true} />
                                    {isVisible2 ?
                                        <span className="k-icon-xxl k-font-icon reset-retype-pass-icon k-i-eye-slash" onClick={() => setIsVisible2(!isVisible2)}></span> :
                                        <span className="k-icon-xxl k-font-icon reset-retype-pass-icon k-i-eye" onClick={() => setIsVisible2(!isVisible2)}></span>
                                    }
                                </div>
                            </FieldWrapper>

                            <div style={{ display: "flex", flexDirection: "row", gap: "15vw", alignItems: "end", margin: "45px 0px 0px 5px" }}>

                                <Button
                                    onClick={handleBackClick}
                                    themeColor={'secondary'}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                >
                                    Back
                                </Button>
                                <Button
                                    themeColor={'primary'}
                                    type={'submit'}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    disabled={!isFormValid}
                                >
                                    Continue
                                </Button>
                            </div>
                        </fieldset>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>

    );
}