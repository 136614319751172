import React, { useState } from "react";

import "../../src/assets/css/Features.css"

import { fetchFeatures } from "../api/MarketingApi";
import pageheader from "../assets/images/page-header.jpg";
import { useNavigate } from "react-router-dom";

export default function News() {
  const navigate = useNavigate();
  const [featuresList, setFeaturesList] = useState([]);

  let FetchFeatureList = async () => {
    let resp = await fetchFeatures();
    let sorted = resp.sort((a, b) => a.displayOrder - b.displayOrder)

    setFeaturesList(sorted)
  }

  React.useEffect(() => {
    try {
      FetchFeatureList()
    }
    catch (error) {
      console.log(error);
     }
  }, [])

  return (
    <>
      <section
        className="hero-area bg_img"
        style={{
          backgroundImage: `url(${pageheader})`,
        }}>
        <div className="container">
          <h1 className="title m-0">Features</h1>
        </div>
      </section>
      <div className="breadcrumb-section">
        <div className="container">
          <ul className="breadcrumb">
            <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}> Home</li>
            <div style={{ margin: "0px 10px 0px 10px" }}> - </div>
            <li>Features</li>
          </ul>
        </div>
      </div>

      <section>
        <div className="updates">
          {featuresList.length > 0 ?
            featuresList.map((feature, index) =>
              feature.active ? <section key={index} className={index % 2 == 0 ? "padding-top bg-f3 padding-bottom" : "gray-back reseller-about padding-top padding-bottom"} >
                <div className="container p-lg-0">
                  <div className={`row m-0 flex-wrap-reverse justify-content-between picture-pos ${index % 2 == 0 ? 'picture-pos-reverse' : ""}`}>
                    <div className="col-lg-6">
                      <div className="text-center reseller-about-thumb update-img">
                        <img src={feature.pictureUrl} alt="BuilderID" />
                      </div>
                    </div>
                    <div className="col-lg-6 p-0" >
                      <div className="overview-non-masking-left padding-top padding-bottom mr-lg-15-xl mt-max-md--40">
                        <div className="news-card-container">
                          <h2 className="title"> {feature.featureName}</h2>
                          <div>
                            {feature.description.split(/\r?\n|\n/g).map((desc, index) => <p style={{ margin: "12px 0px 12px 0px" }} key={index}>{desc}</p>)    /* Splits String so it creates a new line to be more readable */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> : ""
            ) : ""}

        </div>
      </section>
    </>
  );
}
