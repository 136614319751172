import React from 'react';

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { EmailInput, FormInput, FormMaskedTextBox, FormDateInput } from "../../../common/utils/form-components"
import { emailValidator, passwordValidationMessage, confirmPasswordValidationMessage, phoneValidator, firstNameValidator, lastNameValidator, cardValidator, cvcValidator, requiredValidator } from "../../../common/utils/validators"
import { Upload, UploadFileStatus } from "@progress/kendo-react-upload";
import { CardSelector } from './CardSelector';
import logo from "../../../assets/images/logo/logo01.png"
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from "react-router-dom";

export default function EditPayment() {
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");


    let onSaveRequest = (files, _, onProgress) => {
        console.log(files)

        const uid = files[0].uid;
        console.log(uid)
    }

    return (
        <div className="edit-payment-container">
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Card style={{ minWidth: 350, padding:"15px" }}>
                    <CardTitle className='card-title'>
                        Edit your Payment options
                    </CardTitle>

                    <CardBody>
                        <Form render={formRenderProps => <FormElement >
                            <fieldset className={'k-form-fieldset'}>

                                <div >
                                    <Field key={'cardType'} name={'cardType'} component={CardSelector} validator={requiredValidator} />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{
                                            width: '75%',
                                            marginRight: '25px'
                                        }}>
                                            <Field key={'cardNumber'} id={'cardNumber'} name={'cardNumber'} label={'Card Number'} hint={'Hint: Your Credit Card Number'} mask={'0000-0000-0000-0000'} component={FormMaskedTextBox} validator={cardValidator} />
                                        </div>
                                        <Field key={'cvc'} id={'cvc'} name={'cvc'} label={'CVC Number'} hint={'Hint: The last 3 digids on the back of the Card'} mask={'000'} component={FormMaskedTextBox} validator={cvcValidator} />
                                    </div>
                                    <Field key={'expireDate'} id={'expireDate'} name={'expireDate'} label={'Expiration Date'} component={FormDateInput} validator={requiredValidator} />
                                    <Field key={'cardHolder'} id={'cardHolder'} name={'cardHolder'} label={'Card Holder Name'} component={FormInput} validator={requiredValidator} />
                                </div>
                            </fieldset>
                        </FormElement>} />

                        <div style={{ display: "flex", gap: "10vw", marginTop:"20px" }}>

                            <Button
                                style={{ marginTop: "10px" }}
                                themeColor={'secondary'}
                                onClick={() => navigate('/Campaign-Home')}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ marginTop: "10px" }}
                                themeColor={'primary'}
                                onClick={() => navigate('/Campaign-Home')}
                            >
                                Save Changes
                            </Button>
                        </div>


                    </CardBody>
                </Card>
            </div>
        </div>

    );
}

