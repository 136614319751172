import React, { useEffect, useState } from 'react';

const Sitemap = ({ blogList }) => {
    const [sitemapXML, setSitemapXML] = useState('');

    // Generate sitemap
    function generateSitemap() {
        const urls = [
            { loc: "https://mybuilderid.com/", lastmod: "2024-11-07", priority: "1.00" },
            { loc: "https://mybuilderid.com/about", lastmod: "2024-11-06", priority: "0.80" },
            { loc: "https://mybuilderid.com/blog", lastmod: "2024-11-07", priority: "0.80" },
            { loc: "https://mybuilderid.com/contact", lastmod: "2024-11-06", priority: "0.60" },
            { loc: "https://mybuilderid.com/features", lastmod: "2024-11-06", priority: "0.70" },
            { loc: "https://mybuilderid.com/faq", lastmod: "2024-11-06", priority: "0.70" },
            ...(blogList?.map(blog => ({
                loc: `https://mybuilderid.com/${blog.slug}`,
                lastmod: blog.updatedAt || "2024-11-07",
                priority: "0.60",
            })) || [])
        ];

        const urlset = urls.map(url => `
      <url>
        <loc>${url.loc}</loc>
        <lastmod>${url.lastmod}</lastmod>
        <priority>${url.priority}</priority>
      </url>
    `).join("");

        return `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${urlset}
      </urlset>`;
    }

    // Generate and set sitemap XML when blogList updates
    useEffect(() => {
        if (blogList) {
            setSitemapXML(generateSitemap());
        }
    }, [blogList]);

    return (sitemapXML)
}

export default Sitemap;
