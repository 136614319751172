import * as React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
//Card Images
import Visa from "../../../assets/images/Visa.png";
import MasterCard from "../../../assets/images/MasterCard.png";
import PayPal from "../../../assets/images/PayPal.png";


export const CardSelector = props => {

    const {
        value,
        valid
    } = props;

    const [cardHover, setCardHover] = React.useState('');
    const onMouseEnter = React.useCallback(event => setCardHover(event.currentTarget.getAttribute('for')), [setCardHover]);
    const onMouseLeave = React.useCallback(() => setCardHover(''), [setCardHover]);
    const onChange = React.useCallback(event => props.onChange({
        value: event.currentTarget.value
    }), [props]);

    const filter = 'brightness(1.8) grayscale(1) opacity(.7)';
    const filterHover = 'brightness(1.2) grayscale(.5) opacity(.9)';
    const cardStyle = {
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'inline-block',
        width: window.location.pathname == "/Add-New-Campaign" ? 100 : 160,
        height: window.location.pathname == "/Add-New-Campaign" ? 50 : 70,
        border: '1px solid'
    };


    const visaStyle = {

        backgroundImage: `url(${Visa})`,
        filter: value === 'visa' ? 'none' : cardHover === 'visa' ? filterHover : filter,
        ...cardStyle
    };

    const masterCardStyle = {
        backgroundImage: `url(${MasterCard})`,
        filter: value === 'masterCard' ? 'none' : cardHover === 'masterCard' ? filterHover : filter,
        ...cardStyle
    };

    const paypalStyle = {
        backgroundImage: `url(${PayPal})`,
        filter: value === 'paypal' ? 'none' : cardHover === 'paypal' ? filterHover : filter,
        ...cardStyle
    };

    const inputStyles = {
        visibility: 'hidden',
        position: 'absolute'
    };

    return <FieldWrapper>
        <label className={`k-label${valid ? '' : ' k-text-error'}`}>Payment Type</label>
        <ul className="k-radio-list k-list-horizontal payment-comp" >
            <li className="k-radio-item">
                <input onChange={onChange} checked={value === 'visa'} id="visa" type="radio" value="visa" name="paymentType" style={inputStyles} />
                <label onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} htmlFor="visa" style={visaStyle} />
            </li>

            <li className="k-radio-item">
                <input onChange={onChange} checked={value === 'masterCard'} id="masterCard" type="radio" value="masterCard" name="paymentType" style={inputStyles} />
                <label onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} htmlFor="masterCard" style={masterCardStyle} />
            </li>

            <li className="k-radio-item">
                <input onChange={onChange} checked={value === 'paypal'} id="paypal" type="radio" value="paypal" name="paymentType" style={inputStyles} />
                <label onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} htmlFor="paypal" style={paypalStyle} />
            </li>
        </ul>
    </FieldWrapper>;
};