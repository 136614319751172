import { restPost } from "./RestClient";

export async function fetchFeatures() {
	const res = await restPost(`marketing/FetchFeatures`, {});
	return res.data;
}

export async function fetchFAQ() {
	const res = await restPost(`marketing/FetchFAQ`, {});
	return res.data;
}
//Blog
export async function fetchBlogPosts(data) {
	const res = await restPost(`marketing/FetchBlogPosts`, data);
	return res.data;
}
export async function fetchBlogPostContent(data) {
	const res = await restPost(`marketing/FetchBlogPostContent`, data);
	return res.data;
}

