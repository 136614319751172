import React from 'react';
import logo from "../../../assets/images/logo/logo01.png"

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { emailValidator, passwordValidator, passwordValidationMessage, verificationCodeValidator, firstNameValidator, lastNameValidator, confirmPasswordValidationMessage } from "../../../common/utils/validators"
import { Button } from '@progress/kendo-react-buttons';
import { EmailInput, FormInput, FormMaskedTextBox } from "../../../common/utils/form-components"
import { useNavigate } from "react-router-dom";
import { profileUpdate } from "../../../api/CampaignApi"
import { Upload, UploadFileStatus } from "@progress/kendo-react-upload";

export default function EditProfile(props) {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = React.useState(true);

    const [email, setEmail] = React.useState("");

    const [userDetails, setUserDetails] = React.useState({
        FirstName: "",
        LastName: "",
        BusinessName: "",
        PictureUrl: "",
        ContactNumber: "",
        Address: "",
        City: "",
        Province: "",
        Country: "",
        PostalCode: "",
        Password: "",
    });

    // Function to update the data when the user adds Input
    let onDataChange = (field, input) => {
        setUserDetails({
            ...userDetails,
            [field]: input
        })
    }


    let handleSaveProfile = async () => {
        await profileUpdate(userDetails, email);

        props.setAlertMessage("Successfully updated your account")
        props.onTriggerAlert('success')


        navigate('/Campaign-Home')
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center", margin:"10px" }}>

                <Card className="login-container" style={{
                    minWidth: 350
                }}>

                    <CardTitle className='card-title'>
                        Edit Profile Details
                    </CardTitle>

                    <CardBody>
                        <Form render={formRenderProps => <FormElement >
                            <fieldset className={'k-form-fieldset'}>

                                <div>

                                    <div style={{ textAlign: "center", marginTop: "15px" }}>

                                        <img src="https://media.wired.com/photos/598e35fb99d76447c4eb1f28/master/pass/phonepicutres-TA.jpg" width="135vmin" height="120vmax" style={{borderRadius:"4px"}}/>
                                    </div>
                                    <div style={{ fontWeight: "600px" }}>
                                        Edit Profile Photo
                                    </div>
                                    {/* Edit profile image upload */}
                                    <FieldWrapper>
                                        <Upload
                                            batch={false}
                                            multiple={false}
                                            defaultFiles={[]}
                                            withCredentials={false}
                                            saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
                                            removeUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/remove"}
                                            restrictions={{allowedExtensions: [".jpg", ".png"]}}
                                            onAdd={(e) => onDataChange("PictureUrl", e.newState[0].name)}
                                        />
                                    </FieldWrapper>
                                </div>


                                <div
                                    className="sign-up-fields-beside"
                                    style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    {/* First Name */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("FirstName", e.value)} name={'firstName'} component={Input} label={'First Name'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                    {/* Last Name */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("LastName", e.value)} name={'lastName'} component={Input} label={'Last Name'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                </div>
                                <div
                                    className="sign-up-fields-beside"
                                    style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    {/* Company Name */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("BusinessName", e.value)} name={'companyName'} component={Input} label={'Company Name'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                    {/* Address */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("Address", e.value)} name={'address'} component={Input} label={'Address'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                </div>

                                {/* Email Address */}
                                <FieldWrapper>
                                    <div className='k-form-field-wrap'>
                                        <Field onChange={(e) => setEmail(e.value)} name={"email"} type={"email"} component={EmailInput} label={"Email"} validator={emailValidator} />
                                    </div>
                                </FieldWrapper>

                                <div
                                    className="sign-up-fields-beside "
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: "100%"
                                    }}>

                                    {/* City */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("City", e.value)} name={'city'} component={Input} label={'City'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                    {/* Province */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("Province", e.value)} name={'province'} component={Input} label={'Province'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                </div>

                                <div
                                    className="sign-up-fields-beside "
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: "100%"
                                    }}>

                                    {/* Country */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("Country", e.value)} name={'country'} component={Input} label={'Country'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                    {/* Postal Code */}
                                    <FieldWrapper>
                                        <div className='k-form-field-wrap'>
                                            <Field style={{ width: "100%" }} onChange={(e) => onDataChange("PostalCode", e.value)} name={'postalCode'} component={Input} label={'Postal Code'} /*validator={firstNameValidator} */ />
                                        </div>
                                    </FieldWrapper>
                                </div>

                                {/* Password */}
                                <FieldWrapper>
                                    <div className='k-form-field-wrap'>
                                        {/* <Field name={'pass'} component={Input} labelClassName={'k-form-label'} label={'Password'} /> */}
                                        <Input onChange={(e) => onDataChange("Password", e.value)} label={'Password'} name={'password'} type={isVisible ? 'password' : 'text'} validationMessage={passwordValidationMessage} />
                                        {isVisible ?
                                            <span className="k-icon-xxl k-font-icon edit-profile-pass-icon k-i-eye" onClick={() => setIsVisible(!isVisible)}></span> :
                                            <span className="k-icon-xxl k-font-icon edit-profile-pass-icon k-i-eye-slash" onClick={() => setIsVisible(!isVisible)}></span>
                                        }
                                    </div>
                                </FieldWrapper>
                            </fieldset>
                        </FormElement>} />
                    </CardBody>

                    <div style={{ display: "flex", gap: "10vw", marginTop: "15px" }}>

                        <Button
                            style={{ marginTop: "10px" }}
                            themeColor={'secondary'}
                            onClick={() => navigate('/Campaign-Home')}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginTop: "10px" }}
                            themeColor={'primary'}
                            onClick={() => handleSaveProfile()}
                        >
                            Save Changes
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    );
}

