import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer style={{ marginTop: "auto"/*position:"absolute" , left: '0', bottom: '0', right: '0'*/ }}>
      <div className="footer-bottom text-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-8" style={{margin:"auto" }}>
              <p >
                Empowering the Construction Community
              </p>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="footer-widget footer-about">
                <ul className="footer-social">
                  <li>
                    <a href="https://www.facebook.com/mybuilderid">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/mybuilderid">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mybuilderid/ ">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/my-builder-id/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@mybuiderid.com">
                      <i className="fa fa-envelope"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
