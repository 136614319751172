import * as React from 'react';
import "../../assets/css/Login.css";

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { EmailInput, FormInput } from "../../common/utils/form-components"

import { emailValidator, passwordValidator, passwordValidationMessage, verificationCodeValidator } from "../../common/utils/validators"
import { Button } from '@progress/kendo-react-buttons';
import { verifyResetCodeAdsClients } from '../../api/LoginApi';


export default function VerifyCode(props) {
    const [email, setEmail] = React.useState("");
    const [verificationCode, setVerificationCode] = React.useState("");

    async function handleVerify(e) {


        try {
            await verifyResetCodeAdsClients(email, verificationCode, "1212312")

            props.onTriggerAlert('success')
            props.setAlertMessage("Reset Code is valid")

            props.setActivePage("reset-password")
        }
        catch (error) {
            props.onTriggerAlert('error')
            // props.setAlertMessage(error?.response?.data?.message)
            props.setAlertMessage("One of the entered fields are incorrect ")
        }
    }

    let handleBackClick = () => {
        props.setActivePage("forgot-password")
    }

    return (
        <div style={{ margin:"auto"}}>
            <Card className="login-container" style={{ minWidth: 350 }}>
                <CardTitle className='card-title'>
                    Verify Reset Code
                </CardTitle>

                <CardBody>

                    <Form onSubmit={handleVerify} render={formRenderProps => <FormElement >
                        <fieldset className={'k-form-fieldset'}>

                            {/* Email */}
                            <FieldWrapper>
                                <Field onChange={(e) => setEmail(e.value)} name={"email"} type={"email"} component={EmailInput} label={"Email"} validator={emailValidator} />
                            </FieldWrapper>

                            {/* Email */}
                            <FieldWrapper>
                                <Field onChange={(e) => setVerificationCode(e.value)} name={"verificationCode"} type={"verificationCode"} component={FormInput} label={"Verification Code"} validator={verificationCodeValidator} />
                            </FieldWrapper>



                        </fieldset>
                        <div style={{ display: "flex", gap: "30px", alignItems: "center", marginTop: "45px" }}>


                            <Button
                                onClick={handleBackClick}
                                themeColor={'secondary'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            >
                                Back
                            </Button>
                            <Button
                                themeColor={'primary'}
                                type={'submit'}
                                // style={{ padding: "15px 188px 15px 188px", margin: "10px -16px 0px 0px" }}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                disabled={!formRenderProps.allowSubmit}
                            >
                                Verify
                            </Button>
                        </div>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>

    )
}

