import axios from "axios";
import { BASE_URL, restGet, restPost, restPostLogin } from "./RestClient";


export const token = localStorage.getItem('token');

export let headers = {
	"Content-Type": "application/json",
	"Accept": "application/json",
	"Authorization": ('Bearer ' + localStorage.getItem('token')),
}

export let form_headers = {
	"Accept": "application/json",
	"Content-Type": "multipart/form-data",
	"Authorization": ('Bearer ' + localStorage.getItem('token')),
}

let headers_without_authorization = {
	"Content-Type": "application/json",
	"Accept": "application/json"
}



export async function generateResetCode(userEmail) {
	const res = await restGet("users/GenerateResetCode2/" + userEmail, headers_without_authorization);
	return res;
}

export async function isValidResetCode(userEmail, resetCode) {
	const res = await restGet("users/IsValidResetCode/" + userEmail + "/" + resetCode, headers_without_authorization);
	headers.Authorization = 'Bearer ' + res ?? null;
	return res;
}


// --------------------------------------------------------------------


export async function createAccountAdsClients(data) {
	const res = await restPost(`Ads_Clients/CreateAccount_Ads_Clients`, data);
	return res.data;
}
export async function loginAdsClients(data) {
	const res = await restPost(`Ads_Clients/login_Ads_Clients`, data);
	return res;
}
export async function adsSavePassword(data) {
	const res = await restPost(`Ads_Clients/AdsClients_SaveNewPassword`, data);
	return res.data;
}
export async function forgotPasswordAdsClients(email) {
	const res = await restGet(`Ads_Clients/ForgotPassword_Ads_Clients/${email}` );
	return res;
}
export async function verifyResetCodeAdsClients(email, resetCode, deviceId) {
	const res = await restGet(`Ads_Clients/VerifyResetCode/${email}/${resetCode}/${deviceId}` );
	return res;
}