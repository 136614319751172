import * as React from 'react';
import "../../../assets/css/Login.css";

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { emailValidator, passwordValidator, passwordValidationMessage, verificationCodeValidator, firstNameValidator, lastNameValidator, confirmPasswordValidationMessage } from "../../../common/utils/validators"
import { Button } from '@progress/kendo-react-buttons';
import { adsLogin, userLoginToken, isValidResetCode } from '../../../api/LoginApi';
import { EmailInput, FormInput, FormMaskedTextBox } from "../../../common/utils/form-components"


export default function BusinessDetails(props) {

    return (
        <div style={{ display: "flex", justifyContent: "center", margin:"5% 0px 5% 0px" }}>

            <Card className="sign-up-card" style={{
                margin: "10px",
                minWidth: 350
            }}>

                <CardTitle className='card-title'>
                    Register your Business
                </CardTitle>

                <CardBody>
                    <Form render={formRenderProps => <FormElement >
                        <fieldset className={'k-form-fieldset'}>


                            <FieldWrapper>
                                <div
                                    className="sign-up-fields-beside"
                                    style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    {/* Business Name */}
                                    <Field style={{ width: "48%" }} onChange={(e) => props.onDataChange("BusinessName", e.value)} name={'businessName'} component={Input} label={'Business Name'} /*validator={firstNameValidator} */ />
                                    {/* Business Address */}
                                    <Field style={{ width: "48%" }} onChange={(e) => props.onDataChange("Address", e.value)} name={'address'} component={Input} label={'Address'} /*validator={lastNameValidator}*/ />
                                </div>
                            </FieldWrapper>
                            <FieldWrapper>
                                <div
                                    className="sign-up-fields-beside"
                                    style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    {/* City */}
                                    <Field style={{ width: "48%" }} onChange={(e) => props.onDataChange("City", e.value)} name={'city'} component={Input} label={'City'} /*validator={firstNameValidator} */ />
                                    {/* Province */}
                                    <Field style={{ width: "48%" }} onChange={(e) => props.onDataChange("Province", e.value)} name={'province'} component={Input} label={'Province'} /*validator={lastNameValidator}*/ />
                                </div>
                            </FieldWrapper>
                            <FieldWrapper>
                                <div
                                    className="sign-up-fields-beside"
                                    style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                    {/* postalCode */}
                                    <Field style={{ width: "48%" }} onChange={(e) => props.onDataChange("PostalCode", e.value)} name={'postalCode'} component={Input} label={'Postal Code'} /*validator={firstNameValidator} */ />
                                    {/* country */}
                                    <Field style={{ width: "48%" }} onChange={(e) => props.onDataChange("Country", e.value)} name={'country'} component={Input} label={'Country'} /*validator={lastNameValidator}*/ />
                                </div>
                            </FieldWrapper>


                            {/* Phone Number*/}
                            {/* <FieldWrapper> */}
                            <Field onChange={(e) => props.onDataChange("ContactNumber", e.value)} id={'phoneNumber'} name={'phoneNumber'} label={'Business Phone Number'} mask={'(000) 000-0000'} component={FormMaskedTextBox} />
                            {/* </FieldWrapper> */}

                            {/* Business Email */}
                            {/* <Field onChange={(e) => props.onDataChange("businessEmail", e.value)}  name={"email"} type={"email"} component={EmailInput} label={"Business Email"} /> */}


                        </fieldset>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>

    )
}

