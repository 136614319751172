import React from 'react';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp/SignUp';
import LoginPlanCards from './LoginPlanCards';
import VerifyCode from './VerifyCode';
import SignUpHub from './SignUp/SignUpHub';
import ResetPassword from './ResetPassword';

export default function LoginHub(props) {
    const [activePage, setActivePage] = React.useState("login-plan-cards");

    function getActiveForm() {

        // switch (token ? "main" : activePage) {
        switch (activePage) {
            case "login-plan-cards":
                return (
                    <LoginPlanCards setActivePage={setActivePage} />
                )
            case "login":
                return (
                    <LoginForm setActivePage={setActivePage} onTriggerAlert={(flag) => props.onTriggerAlert(flag)} setAlertMessage={props.setAlertMessage} />
                )
            case "forgot-password":
                return (
                    <ForgotPassword setActivePage={setActivePage} onTriggerAlert={(flag) => props.onTriggerAlert(flag)} setAlertMessage={props.setAlertMessage} />
                )
            case "sign-up-hub":
                return (
                    <SignUpHub setActivePage={setActivePage} onTriggerAlert={(flag) => props.onTriggerAlert(flag)} setAlertMessage={props.setAlertMessage} />
                )
            // case "sign-up":
            //     return (
            //         <SignUp setActivePage={setActivePage} />
            //     )
            case "verify-code":
                return (
                    <VerifyCode setActivePage={setActivePage} onTriggerAlert={(flag) => props.onTriggerAlert(flag)} setAlertMessage={props.setAlertMessage} />
                )
            case "reset-password":
                return (
                    <ResetPassword setActivePage={setActivePage} onTriggerAlert={(flag) => props.onTriggerAlert(flag)} setAlertMessage={props.setAlertMessage} />
                )
            default:
                return (<>No Active Page Found</>)
        }
    }

    return (
        getActiveForm()
    );
}

