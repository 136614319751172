import SendUsMessage from "../common/components/SendUsMessage";
import pageheader from "../assets/images/page-header.jpg";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const navigate = useNavigate();

  return (
    <>
      <section
        className="hero-area bg_img"
        style={{
          backgroundImage: `url(${pageheader})`,
        }}
      >
        <div className="container">
          <h1 className="title m-0">Contact Us</h1>
        </div>
      </section>
      <div className="breadcrumb-section">
        <div className="container">
          <ul className="breadcrumb">
            <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}> Home</li>
            <div style={{ margin: "0px 10px 0px 10px" }}> - </div>

            <li>Contact</li>
          </ul>
        </div>
      </div>

      <SendUsMessage />

      <section className="office-info padding-top padding-bottom">
        <div className="container">
          <div className="section-header">
            <h2 className="title">office contact info</h2>
          </div>
          <div className="row justify-content-center mb-30-none contact-container" style={{gap:"50px"}}>
            <div className="contact-item" style={{padding:"30px", width:"28vh"}}   >
              <div className="contact-thumb" >
                <i className="flaticon-route"></i>
              </div>
              <div className="contact-content" >
                <h4 className="title">office address</h4>
                <ul >
                  <li>2335137 Alberta LTD.
                  </li>
                  <li>
                    1000, 903 - 8 Avenue S.W.</li>
                  <li>
                    Calgary, Alberta, T2P0P7</li>
                </ul>
              </div>
            </div>

            <div className="contact-item" style={{padding:"30px", width:"28vh"}}>
              <div className="contact-thumb">
                <i className="flaticon-global"></i>
              </div>
              <div className="contact-content">
                <h4 className="title">email address</h4>
                <ul>
                  <li>
                    <a href="mailto:info@mybuilderid.com">
                      info@mybuilderid.com{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section >
    </>
  );
}
