import React, { useEffect, useState } from "react";
import contactBg from "../../assets/images/contact-bg.png";

const SendUsMessage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [inputError, setInputError] = useState({ type: null, message: null });
  const [captcha, setCaptcha] = useState("");
  const [userText, setUserText] = useState("");

  useEffect(() => {
    initializeCaptcha();
  }, []);

  const initializeCaptcha = () => {
    const alphaNums = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    let captchaArr = [];
    for (let i = 1; i <= 7; i++) {
      captchaArr.push(alphaNums[Math.floor(Math.random() * alphaNums.length)]);
    }
    setCaptcha(captchaArr.join("")?.trim());
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    try {
      if (!name.trim()) {
        setInputError({ type: "name", message: "Please enter a name" });
        return;
      }

      if (!email.trim()) {
        setInputError({ type: "email", message: "Please enter an email" });
        return;
      } else if (!isValidEmail(email)) {
        setInputError({
          type: "vaildEmail",
          message: "Please enter a valid email",
        });
        return;
      }

      if (!message.trim()) {
        setInputError({ type: "message", message: "Please enter a message" });
        return;
      }

      if (userText.trim() !== captcha.trim()) {
        setInputError({
          type: "captcha",
          message: "Incorrect captcha, please try again",
        });
        return;
      }

      setInputError({ type: null, message: null });
      var data = {
        UserId: 1,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: name,
            Email: email,
            Message: message,
            RecordStatusId: 1,
            ModifiedBy: 1,
          },
        ],
      };

      await fetch(
        "https://bimiscwebapi-test.azurewebsites.net/api/users/SaveContactUs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          setEmail("");
          setName("");
          setMessage("");
          setInputError({ type: null, message: null });
          initializeCaptcha();
          setUserText("");
          alert("Message sent successfully");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (err) {
      console.log("Error ::", err);
    }
  };

  const isSumbitButtonDisable = () => {
    return (
      !name.trim() ||
      !email.trim() ||
      !isValidEmail(email) ||
      !message.trim() ||
      captcha.trim() !== userText.trim()
    );
  };

  const handleRefresh = () => {
    initializeCaptcha();
    setUserText("");
  };

  return (
    <>
      <section
        className="account-section padding-top padding-bottom"
        style={
          props?.from !== "features"
            ? {
                backgroundImage: `url(${contactBg})`,
                backgroundPosition: "bottom center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }
            : {}
        }>
        <div className="container">
          <div
            className={
              props?.from !== "features" ? "account-wrapper" : "form-wrapper"
            }>
            <div
              className={props?.from !== "features" ? "account-area" : "form-area"}>
              <h3
                className={
                  props?.from !== "features" ? "account-title" : "form-title"
                }>
                send us a message
              </h3>
              <form className="contact-form" id="contact_form_submit">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={handleNameChange}
                    required
                  />
                </div>
                <p id="nameError" className="form_error_msg">
                  {inputError?.type === "name" &&
                    !name.trim() &&
                    inputError?.message}
                </p>
                <div className="form-group">
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <p id="emailError" className="form_error_msg">
                  {inputError?.type === "email" &&
                    !email.trim() &&
                    inputError?.message}
                </p>
                <p id="emailError" className="form_error_msg">
                  {inputError?.type === "vaildEmail" &&
                    !isValidEmail(email) &&
                    inputError?.message}
                </p>
                <div className="form-group">
                  <textarea
                    id="message"
                    placeholder="Message"
                    value={message}
                    onChange={handleMessageChange}
                    required></textarea>
                </div>
                <p id="messageError" className="form_error_msg">
                  {inputError?.type === "message" &&
                    !message.trim() &&
                    inputError?.message}
                </p>
                <div>
                  <p
                    style={{
                      fontSize: "30px",
                      color: "#093db9",
                      fontFamily: "Roboto",
                      textAlign: "center",
                      margin: "32px",
                    }}>
                    {captcha}
                  </p>
                  <div id="buttonInside">
                    <input
                      id="textBox"
                      placeholder="Enter captcha text here"
                      type="text"
                      name="text"
                      value={userText}
                      onChange={(e) => {
                        setUserText(e.target.value);
                      }}
                    />
                    <button id="refreshButton" onClick={handleRefresh}>
                      <i className="fa fa-refresh fa-lg"></i>
                    </button>
                  </div>
                  <p id="messageError" className="form_error_msg">
                    {inputError?.type === "captcha" &&
                      captcha.trim() !== userText?.trim() &&
                      inputError?.message}
                  </p>
                </div>
                <div id="disableBtnColor" className="form-group">
                  <input
                    type="button"
                    id="submitButton"
                    style={
                      isSumbitButtonDisable()
                        ? { backgroundColor: "#d8d8d8", color: "#999" }
                        : { backgroundColor: "#083DB8", color: "#999" }
                    }
                    value="Send Message"
                    onClick={handleSubmit}
                    disabled={isSumbitButtonDisable()}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SendUsMessage;
