import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';
import logo from "../../assets/images/logo/logo01.png"
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from "react-router-dom";

// import Header from "../../common/components/Header"

export default function LoginPlanCards(props) {
    const navigate = useNavigate();

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: "1", padding: "10px", /*backgroundColor:"#f2f2f2"*/ }}>
                <div style={{ display: "flex", justifyContent: "center", height: "inherit", marginTop: "auto" }}>

                    <div className="login-cards-container" >
                        <Card className="login-card">
                            <CardBody>
                                <CardTitle>
                                    <h1>
                                        Plan Title
                                    </h1>
                                </CardTitle>

                                <h6 className="plan-card-description" >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                    electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passag
                                </h6>
                            </CardBody>
                        </Card>
                        <Card className="login-card">
                            <CardBody>
                                <CardTitle>
                                    <h1>
                                        Plan Title
                                    </h1>
                                </CardTitle>
                                <h6 className="plan-card-description"  >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                    electronic typesetting
                                </h6>
                            </CardBody>
                        </Card>
                        <Card className="login-card">
                            <CardBody>
                                <CardTitle>
                                    <h1>
                                        Plan Title
                                    </h1>
                                </CardTitle>
                                <h6 className="plan-card-description" >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                    electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                    and more recently with
                                </h6>
                            </CardBody>
                        </Card>

                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "auto", paddingTop: "10px", width: "100%", marginBottom:"14px" }}>

                    <Button
                        style={{ padding: "0px 50px 0px 50px", position: "inherit", width: "15%"}}
                        themeColor={'secondary'}
                        onClick={() => navigate("/")}
                    >
                        Back
                    </Button>
                    <Button
                        style={{ padding: "0px 50px 0px 50px", position: "inherit", width: "15%" }}
                        themeColor={'primary'}
                        onClick={() => props.setActivePage("login")}
                    >
                        Go to Login
                    </Button>
                </div>
            </div>
        </>
    );
}



