import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';
import "./../../assets/css/CampaignHome.css"


export default function CampaignHomeCards(props) {

    return <Card
        onClick={() => props.setCardDialogVis(true)}
        className="campaign-card"
        style={{
            width: "100%",
            marginBottom: "20px",
            border:"2px solid lightgray"
        }}>

        <CardBody style={{ display: "flex", }}>
            <CardImage src={props.image} style={{
                // height: '250px',
                // maxWidth: '100%',
                height: '22vmin',
                minHeight: '10%',

                margin:"auto"
                // borderRadius: "5px",
                // boxShadow: '0 0 2px 0 gray',
                // border:"2px solid gray"
            }} />
            <div className='campaign-card-content'>
                <CardTitle style={{ fontSize: "0.7vmax" }}><h1>{props.headerTitle}</h1></CardTitle>
                <CardSubtitle style={{ marginBottom: '20px' }}><p style={{ fontWeight: "600", fontSize: "0.9vmax" }}>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p></CardSubtitle>
                <CardSubtitle><h6>{props.headerSubtitle}</h6></CardSubtitle>
            </div>

        </CardBody>
    </Card>

};