import React from 'react';
import "./../../assets/css/CampaignHome.css"
import { Button } from "@progress/kendo-react-buttons";
import CampaignHomeCards from "./CampaignHomeCards"
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';
import { Slide, Push, Expand, Fade, Zoom, Reveal } from '@progress/kendo-react-animation';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Popup } from "@progress/kendo-react-popup";
import { useNavigate } from "react-router-dom";
import { folderIcon, calendarIcon } from "@progress/kendo-svg-icons";
import logo from "../../assets/images/logo/logo01.png"
import CampaignCardDialog from './CampaignCardDialog';
import { ChipList } from '@progress/kendo-react-buttons';


//TODO DELETE THIS TEMP
const cardsData = [{
    thumbnailSrc: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/bg_flag.jpg',
    headerTitle: 'Facebook',
    headerSubtitle: 'Start Campaign - End Campaign',
    commentsExpanded: false,
    postLiked: false,
    comments: [],
    newCommentTextValue: '',
    postLikes: 174,
    scrollViewItems: {
        url: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila.jpg'
    }
}, {
    thumbnailSrc: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila_lakes.jpg',
    headerTitle: 'Reddit',
    headerSubtitle: 'Start Campaign - End Campaign',
    commentsExpanded: false,
    postLiked: false,
    comments: [],
    newCommentTextValue: '',
    postLikes: 962,
    scrollViewItems: {
        url: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila.jpg'
    }
}, {
    thumbnailSrc: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila_lakes.jpg',
    headerTitle: 'Pinterest',
    headerSubtitle: 'Start Campaign - End Campaign',
    commentsExpanded: false,
    postLiked: false,
    comments: [],
    newCommentTextValue: '',
    postLikes: 962,
    scrollViewItems: {
        url: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila.jpg'
    }
}, {
    thumbnailSrc: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila_lakes.jpg',
    headerTitle: 'YouTube',
    headerSubtitle: 'Start Campaign - End Campaign',
    commentsExpanded: false,
    postLiked: false,
    comments: [],
    newCommentTextValue: '',
    postLikes: 962,
    scrollViewItems: {
        url: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila.jpg'
    }
}, {
    thumbnailSrc: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila_lakes.jpg',
    headerTitle: 'Instagram',
    headerSubtitle: 'Start Campaign - End Campaign',
    commentsExpanded: false,
    postLiked: false,
    comments: [],
    newCommentTextValue: '',
    postLikes: 962,
    scrollViewItems: {
        url: 'https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila.jpg'
    }
}];

const campaigns = [{
    text: 'Current Campaigns',
    value: 'Current Campaigns',
    // svgIcon: facebookIcon
}, {
    text: 'Previous Campaigns',
    value: 'Previous Campaigns',
    // svgIcon: redditIcon
}, {
    text: 'Pending Campaigns',
    value: 'Pending Campaigns',
    // svgIcon: pinterestIcon
},
];

export default function CampaignHome() {
    const navigate = useNavigate();
    const anchor = React.useRef(null);

    const [settingsPopup, toggleSettingsPopup] = React.useState(false)
    const [cardDialogVis, setCardDialogVis] = React.useState(false);
    // const [showHamburgerMenu, setShowHamburgerMenu] = React.useState(false)

    let handleSettingsClick = () => {
        toggleSettingsPopup(!settingsPopup)
    }

    React.useEffect(() => {
        toggleSettingsPopup(false);
    }, []);

    // let handleHamburgerMenu = () => {
    //     setShowHamburgerMenu(!showHamburgerMenu)
    // }

    return (
        <div className='campaign-home'>

            <div className='campaign-home-middle-content'>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px", maxHeight: "40px", }}>

                    {/* Search Filters */}
                    <div className='campaign-chips'>
                        <ChipList defaultData={campaigns} selection="single" defaultValue={['Facebook', 'facebook']} />
                    </div>

                    {/* <div className='hamburger-container'>
                        <div className='hamburger-icon' >
                            <i className="fa fa-bars" onClick={handleHamburgerMenu}></i>
                        </div>
                        {showHamburgerMenu && <div className='hamburger-dropdown''"header-bottom'>
                            <ul>
                                <li>Current Campaigns</li>
                                <li>Previous Campaigns</li>
                                <li>Pending Campaigns</li>
                            </ul>
                        </div>}
                    </div> */}



                    {/* Seach Bar */}
                    <div className='campaign-search'>Search ...</div>
                    {/* Add New Button */}
                    <div className='add-new-campaign-button' /*themeColor={"primary"}*/ onClick={() => navigate("/Add-New-Campaign")} >
                        Add New
                    </div>

                    <div
                        className="settings-gear k-icon k-icon-xxxl k-font-icon k-i-gear"
                        onClick={() => handleSettingsClick()}
                        ref={anchor}
                    />
                    <Popup
                        style={{ marginTop: "20px" }}
                        anchor={anchor.current}
                        popupAlign={{
                            horizontal: 'center',
                            vertical: 'bottom'
                        }}
                        anchorAlign={{
                            horizontal: 'left',
                            vertical: 'top'
                        }}
                        show={settingsPopup}
                        popupClass={"settings-data"}
                    >
                        <div >
                            <div style={{ fontWeight: "600" }}> Hi, Alex Dragulin</div>

                            <div style={{ display: "flex", gap: "10px", margin: "10px 0px 10px 0px" }}>
                                <Button svgIcon={folderIcon} onClick={() => navigate("/Edit-Profile")}>Edit Profile</Button>
                                <Button svgIcon={calendarIcon} onClick={() => navigate("/Edit-Payment")}>Edit Payment</Button>
                            </div>
                            <div className="sign-out" style={{ fontWeight: "600" }} onClick={() => {
                                localStorage.clear()
                                navigate("/")
                            }}>Sign Out</div>
                        </div>
                    </Popup>
                </div>


                {cardsData.map((card, index) => (
                    <React.Fragment key={index}>
                        <CampaignHomeCards image={card.scrollViewItems.url} headerSubtitle={card.headerSubtitle} headerTitle={card.headerTitle} setCardDialogVis={setCardDialogVis} />
                    </React.Fragment>
                ))}

                {/* Dialog Popup for card click */}
                {cardDialogVis &&
                    <CampaignCardDialog setCardDialogVis={setCardDialogVis} />
                }
            </div>
        </div >
    );
}

