
//  Function to simulate dynamic group by functionality

// EX Call
// console.log(groupBy(['one', 'two', 'three'], 'length'));
export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

// EX Call
// const grouped = groupBy(pets, pet => pet.type);
// console.log(grouped.get("Dog"));

export function groupBy2(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

// Function to convert ISO string to readable Day Month Year format
export function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
};
export function formatDateTime(isoDateString) {
    const date = new Date(isoDateString);
    // Define your desired options here
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}


// -----------------------------------------------