import * as React from 'react';
import "../../assets/css/Login.css";

import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { EmailInput, FormInput } from "../../common/utils/form-components"
import { emailValidator, passwordValidator, passwordValidationMessage } from "../../common/utils/validators"
import { Button } from '@progress/kendo-react-buttons';
import { loginAdsClients } from '../../api/LoginApi';
import { useNavigate } from "react-router-dom";
import { sha256 } from 'crypto-hash';

export default function LoginForm(props) {
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [isVisible, setIsVisible] = React.useState(true);

    let handleLogin = async () => {

        try {
            await sha256(password).then(async (hashedPass) => {
                let loginObj = {
                    "Email": email,
                    "Password": hashedPass,
                    "DeviceName": "x",
                    // "Platform": window.navigator.userAgent,
                    "Platform": "x",
                }
                // console.log(window.navigator.userAgent);
                
                if (hashedPass) {
                    let loginResult = await loginAdsClients(loginObj);

                    if (loginResult?.status) {

                        await localStorage.setItem("token", loginResult?.token ?? null);

                        //Alert for Logging in
                        props.onTriggerAlert('success')
                        props.setAlertMessage("You have successfully Logged in!")

                        navigate("/Campaign-Home")
                    }
                }
            })

            // await loginAdsClients(loginObj)
            // let loginResult = await loginAdsClients(loginObj);
            // await localStorage.setItem("token", loginResult?.data?.token ?? null);

        }
        catch (error) {
            props.onTriggerAlert('error')
            // props.setAlertMessage(error?.response?.data ? error.response.data.message : 'Wrong Login Credentials!')
            props.setAlertMessage('Wrong Login Credentials!')
        }
    }


    return (
        <Card className="login-container" style={{ margin: "auto" }}>

            <CardTitle className='card-title'>
                Login
            </CardTitle>

            <CardBody>

                <Form onSubmit={handleLogin} render={formRenderProps => <FormElement style={{
                    // Width: 350 
                }}>
                    <fieldset className={'k-form-fieldset'}>

                        {/* Email */}
                        <FieldWrapper>
                            <Field onChange={(e) => setEmail(e.value)} name={"email"} type={"email"} component={EmailInput} label={"Email"} validator={emailValidator} />
                        </FieldWrapper>

                        {/* Password */}
                        <FieldWrapper>
                            <div className='k-form-field-wrap'>
                                {/* <Field name={'pass'} component={Input} labelClassName={'k-form-label'} label={'Password'} /> */}
                                <Input onChange={(e) => setPassword(e.value)} label={'Password'} name={'password'} type={isVisible ? 'password' : 'text'} validationMessage={passwordValidationMessage} />
                                {isVisible ?
                                    <span className="k-icon-xxl k-font-icon pass-icon k-i-eye" onClick={() => setIsVisible(!isVisible)}></span> :
                                    <span className="k-icon-xxl k-font-icon pass-icon k-i-eye-slash" onClick={() => setIsVisible(!isVisible)}></span>
                                }

                            </div>
                        </FieldWrapper>

                    </fieldset>
                    <div style={{ display: "flex", flexDirection: "column", gap: "30px", alignItems: "center", }}>
                        <Button
                            themeColor={'primary'}
                            type={'submit'}
                            style={{ marginTop: "25px" }}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            disabled={!formRenderProps.allowSubmit}
                        >
                            Login
                        </Button>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div className="forgot-password" onClick={() => props.setActivePage("login-plan-cards")}>
                                Back
                            </div>
                            <div className="forgot-password" onClick={() => props.setActivePage("forgot-password")}>
                                Forgot Password?
                            </div>
                        </div>

                    </div>

                    <span className={"k-form-separator"} style={{ margin: "30px" }} />

                    <div className="sign-up">
                        Need an account? <span onClick={() => props.setActivePage("sign-up-hub")}>SIGN UP</span>
                    </div>
                </FormElement>} />
            </CardBody>
        </Card>
    )
}

