import React from 'react';
import { Stepper } from '@progress/kendo-react-layout';
import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from "react-router-dom";

// DisplayComponent
import PlanCards from './PlanCards';
import CampaignMap from './CampaignMap';
import CampaignData from './CampaignData';
import CampaignOverview from './CampaignOverview';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { checkIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";

const stepPages = [PlanCards, CampaignMap, CampaignData, CampaignOverview];

export default function AddNewCampaign(props) {
    const navigate = useNavigate();

    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const [steps, setSteps] = React.useState([{
        label: 'Campaign Plans',
        isValid: undefined
    }, {
        label: 'Campaign Map',
        isValid: undefined
    }, {
        label: 'Campaign Data',
        isValid: undefined
    }, {
        label: 'Overview',
        isValid: undefined
    }]);
    const [thankYouDialogVis, setThankYouDialogVis] = React.useState(false);

    const toggleThankYouDialog = () => {
        setThankYouDialogVis(!thankYouDialogVis);

        if (thankYouDialogVis) {
            navigate("/Campaign-Home");
        }
    };

    // Instantiate to load as a Component in the return()
    let DisplayComponent = stepPages[step]

    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const isPreviousStepsValid = steps.slice(0, step).findIndex(currentStep => currentStep.isValid === false) === -1;

    // Instantiate to load as a Component in the return()
    const onStepSubmit = React.useCallback(
        (event) => {
            const { isValid, values } = event;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));

            setSteps(currentSteps);
            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);

            // console.log(isLastStep)
            // console.log(isPreviousStepsValid)
            // console.log(isValid)

            if (isLastStep && isPreviousStepsValid /*&& isValid*/) {
                // alert(JSON.stringify(values));

                // props.onTriggerAlert('success')
                setThankYouDialogVis(true)
            }
        },
        [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
    );

    const onPrevStepClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    return (
        <div
            className='add-new-container'
        >
            <Stepper value={step} items={steps} />
            <DisplayComponent
                onTriggerAlert={(flag) => props.onTriggerAlert(flag)}
                setAlertMessage={props.setAlertMessage} />

            {/* Footer of stepper */}
            <div className="footer-stepper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>


                {/* <div className='add-new-campaign-stepper-buttons'>
                    <div>
                        <Button
                            width={'40px'}
                            style={{ padding: "20px 40px 20px 40px" }}
                            themeColor={'secondary'}
                            onClick={() => navigate("/Campaign-Home")}>
                            Home
                        </Button>
                    </div>

                    <div style={{ margin: "10px 0px 0px 0px" }}>Step {step + 1} of {steps.length}</div>


                    <div style={{ display: "flex" }}>
                        {step !== 0 ? <Button style={{
                            marginRight: '16px',
                            // padding: "20px 40px 20px 40px"
                        }}
                            onClick={onPrevStepClick}>
                            Previous
                        </Button> : undefined}
                        <Button
                            style={{ padding: "20px 40px 20px 40px" }}
                            themeColor={'primary'}
                            disabled={isLastStep ? !isPreviousStepsValid : false}
                            onClick={onStepSubmit}>
                            {isLastStep ? 'Place Order' : 'Next'}
                        </Button>
                    </div>
                </div> */}

                <div className="sign-up-stepper-buttons" style={{ display: "flex", justifyContent: 'space-between',/* margin: "auto 0px auto 0px",*/ padding: "10px", width: "68vmin" }}>

                    <div>
                        <Button
                            style={{ padding: "10px" }}
                            themeColor={'secondary'}
                            onClick={() => navigate("/Campaign-Home")}>
                            Home
                        </Button>
                    </div>

                    <div style={{ alignSelf: "center" }}>Step {step + 1} of {steps.length}</div>

                    <div style={{ display: "flex" }}>
                        {step !== 0 ? (
                            <Button
                                style={{ padding: "10px", marginRight: "16px" }}
                                onClick={onPrevStepClick}
                            >
                                Previous
                            </Button>
                        ) : undefined}
                        <Button
                            style={{ padding: "10px", position: "inherit" }}
                            themeColor={'primary'}
                            disabled={isLastStep ? !isPreviousStepsValid : false}
                            onClick={onStepSubmit}
                        >
                            {isLastStep ? 'Process Order' : 'Next'}
                        </Button>
                    </div>
                </div>



            </div>


            {thankYouDialogVis && <Dialog title={" "} onClose={toggleThankYouDialog}>
                <div className="text-center">
                    <SvgIcon icon={checkIcon} size="xxxlarge" />
                </div>
                <h3
                    style={{
                        textAlign: "center",
                        padding: "2vmin 5vmin 0vmin 5vmin"
                    }}
                >
                    Order Submitted!
                </h3>
                <div style={{
                    fontWeight: "600",
                    padding: "40px",
                    textAlign: "center"
                }}>
                    <div>Thank you for your order! We appreciate your support.</div>
                    <div style={{ marginTop: "15px" }}>We will get back to your shortly with a campaign authentication.</div>
                </div>

                <Button
                    themeColor={'primary'}
                    onClick={toggleThankYouDialog}>
                    Continue
                </Button>

            </Dialog>}

        </div>

    );
}

