import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const center = {
    lat: 51.048615,
    lng: -114.070847
};

export default function CampaignMap() {
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: process.env.MAP_API_KEY
    // })

    const [map, setMap] = React.useState(null)


    return (
        <div className="add-campaign-map" >
            {/* <div className='site-chosen'>
                Site Chosen by the user 1
            </div> */}
            <GoogleMap
                mapContainerStyle={{
                    width: '100vmin',
                    // height: '60vmin',
                    height: '100%',
                    borderRadius: "8px",
                    boxShadow: " 0px 0px 8px 2px lightgray"

                }}
                center={center}
                zoom={12}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
            >
                { /* Child components, such as markers, info windows, etc. */}
                <Marker position={center} />
            </GoogleMap>
        </div>
    );
}

